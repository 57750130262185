import { createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "./StoreActionConfig";
import AccountApi from "apis/AccountApi";
import { UserTypeEnum } from "constants/Global";

export const globalInitialState = {
  themeMode: "light", // 'dark'| 'light' | 'media'
  isLoadingModal: false,
  authUser: null,
  isSideMenu: false,
};

const slice = createSlice({
  name: "global",
  initialState: globalInitialState,
  reducers: {
    toggleLoadingModalAction: (state, { payload }) => {
      state.isLoadingModal =
        payload !== undefined ? !!payload : !state.isLoadingModal;
    },
    toggleSideMenuAction: (state, { payload }) => {
      state.isSideMenu = payload !== undefined ? !!payload : !state.isSideMenu;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(logoutAction, () => ({ ...globalInitialState }))
      .addMatcher(
        AccountApi.endpoints.authenticateAccount.matchFulfilled,
        (state, { payload }) => {
          state.authUser = {
            ...state.authUser,
            ...payload,
          };
        }
      )
      .addMatcher(
        AccountApi.endpoints.registerAccount.matchFulfilled,
        (state, { payload }) => {
          state.authUser = {
            ...state.authUser,
            token: payload?.token,
          };
        }
      )
      .addMatcher(
        AccountApi.endpoints.getAccount.matchFulfilled,
        (state, { payload }) => {
          state.authUser = {
            ...state.authUser,
            ...payload,
            isPartner: payload?.accountType === UserTypeEnum.PARTNER,
          };
        }
      )
      .addMatcher(
        AccountApi.endpoints.getAccountProfilePic.matchFulfilled,
        (state, { payload }) => {
          state.authUser = { ...state.authUser, avatar: payload };
        }
      )
      .addMatcher(
        AccountApi.endpoints.refreshAccountToken.matchFulfilled,
        (state, { payload }) => {
          console.log({ payload });
          state.authUser = { ...state.authUser, ...payload };
        }
      ),
});

export const { toggleLoadingModalAction, toggleSideMenuAction } = slice.actions;

export default slice;

export function getGlobalSliceStorageState({ authUser }) {
  return { authUser };
}
