const tailwindDefaultTheme = require("tailwindcss/defaultTheme");

export const MediaQueryBreakpointEnum = {
  "2xl": `(min-width: ${tailwindDefaultTheme.screens["2xl"]})`,
  lg: `(min-width: ${tailwindDefaultTheme.screens.lg})`,
  md: `(min-width: ${tailwindDefaultTheme.screens.md})`,
  sm: `(min-width: ${tailwindDefaultTheme.screens.sm})`,
  xl: `(min-width: ${tailwindDefaultTheme.screens.xl})`,
};

export const APP_SIDE_MENU_WIDTH = 270;

export const PaginationParamsDefault = {
  offset: 0,
  limit: 20,
};

export const PaginationDefault = {
  pageIndex: 0,
  pageSize: 20,
};

export const UserTypeEnum = {
  ROOT: "ROOT",
  ADMIN: "ADMIN",
  DRIVER: "DRIVER",
  RIDER: "RIDER",
  PARTNER: "PARTNER",
};

export const SubDomainEnum = {
  FAAN_DASHBOARD: "faan-dashboard",
  DEFAULT: "gocaby",
};
