import store from "./StoreConfig";
import { logoutAction } from "./StoreActionConfig";
import { AppHttp } from "./HttpConfig";
import AccountApi from "apis/AccountApi";

AppHttp.interceptors.request.use((config) => {
  const { token, userId, isPartner } = store.getState().global.authUser || {};

  if (isPartner) {
    config.params = { ...config.params };
  }

  if (token && !config?.__isRequestRefreshToken) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers["X-Auth-User"] = userId;
  }

  return config;
});

AppHttp.interceptors.response.use(undefined, async (error) => {
  let _error = error;
  if ([401].includes(error?.response?.status)) {
    if (!error?.config?.__isRequestRefreshToken) {
      const { refreshToken } = store.getState().global.authUser || {};
      if (refreshToken) {
        try {
          return await requestRefreshToken(() => {
            // JSON.parse(JSON.stringify(config.headers || {})) as RawAxiosRequestHeaders;
            return AppHttp.request(error.config);
          });
        } catch (error) {
          _error = error;
        }
      }
    }
    store.dispatch(logoutAction());
  }
  return Promise.reject(_error);
});

let isRefreshing = false;
/** @type {Promise<any>} */
let refreshingAxiosPromise = null;

function requestRefreshToken(cb) {
  if (isRefreshing) {
    return cb ? refreshingAxiosPromise.then(cb) : refreshingAxiosPromise;
  }
  const { refreshToken } = store.getState().global.authUser || {};

  isRefreshing = true;

  refreshingAxiosPromise = store
    .dispatch(
      AccountApi.endpoints.refreshAccountToken.initiate(
        {
          __isRequestRefreshToken: true,
          data: { refreshToken },
        },
        { track: true }
      )
    )
    .unwrap()
    .finally(() => {
      isRefreshing = false;
      refreshingAxiosPromise = null;
    });

  return cb ? refreshingAxiosPromise.then(cb) : refreshingAxiosPromise;
}
