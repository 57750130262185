import { AppApi } from "configs/StoreQueryConfig";
import { UserTypeEnum } from "constants/Global";
import { StoreQueryTagEnum } from "constants/StoreConstants";
import { downloadFile } from "utils/FileUtils";
import { getAppSubdomainBooleans } from "utils/GlobalUtils";
import { objectToFormData } from "utils/ObjectUtils";
import { providesTags, invalidatesTags } from "utils/QueryUtils";

export const ACCOUNT_API_URL = "/account";

export const AccountApi = AppApi.injectEndpoints({
  endpoints: (builder) => ({
    // account controller
    registerAccount: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1`,
        method: "post",
        ...config,
      }),
    }),
    authenticateAccount: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/authenticate`,
        method: "post",
        ...config,
      }),
    }),
    // dashboard export controller
    exportAccount: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/dashboard/exports/account`,
        responseType: "blob",
        ...config,
      }),
      onQueryStarted: async (config, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          downloadFile(data, config?.params.fileName);
        } catch (error) {
          console.log(error);
        }
      },
    }),
    // dashboard account controller
    getDashboardStats: builder.query({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/dashboard/stats`,
        method: "get",
        ...config,
      }),
      providesTags: [{ type: StoreQueryTagEnum.USER }],
    }),
    getDashboardAccountList: builder.query({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/dashboard/account/list`,
        method: "get",
        ...config,
      }),
      providesTags: (data) =>
        providesTags(data?.content || data?.resp, StoreQueryTagEnum.USER),
    }),
    createDashboardAccount: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/dashboard/account/create`,
        method: "post",
        ...config,
      }),
      invalidatesTags: (data, error) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER),
    }),
    updateDashboardAccountStatus: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/dashboard/account/status`,
        method: "patch",
        ...config,
      }),
      invalidatesTags: (data, error) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER),
    }),
    updateDashboardAccount: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${ACCOUNT_API_URL}/v1/dashboard/account/${path?.accountId}/update`,
        method: "patch",
        ...config,
      }),
      invalidatesTags: (data, error) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER),
    }),
    getDashboardAccount: builder.query({
      query: ({ path, ...config }) => ({
        url: `${ACCOUNT_API_URL}/v1/dashboard/account/${path?.accountId}`,
        ...config,
      }),
      providesTags: (data) => providesTags([data], StoreQueryTagEnum.USER),
    }),
    flagDashboardAccountProfilePic: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${ACCOUNT_API_URL}/v1/dashboard/account/${path?.accountId}/flag-profile-pic`,
        method: "patch",
        ...config,
      }),
      invalidatesTags: (data, error) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER),
    }),
    // file upload controller
    uploadAccountProfilePic: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/uploads/profile-pic`,
        method: "post",
        ...config,
        data: objectToFormData(config.data),
      }),
      invalidatesTags: (data, error) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER),
    }),
    // instrospect
    getAccountInstrospect: builder.query({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/instrospect`,
        ...config,
      }),
      providesTags: (data) => providesTags([data], StoreQueryTagEnum.USER),
    }),
    // profile controller
    getAccountBasicDetails: builder.query({
      query: ({ path, ...config }) => ({
        url: `${ACCOUNT_API_URL}/v1/accounts/${path.accountId}`,
        ...config,
      }),
      providesTags: (data) => providesTags([data], StoreQueryTagEnum.USER),
    }),
    getAccount: builder.query({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/me`,
        ...config,
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const data = await queryFulfilled;
          const { isDefault } = getAppSubdomainBooleans();
          if (data?.accountType === UserTypeEnum.PARTNER && isDefault) {
            // window.location =
          }
        } catch (error) {}
      },
      providesTags: (data) => providesTags([data], StoreQueryTagEnum.USER),
    }),
    updateAccount: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/me`,
        method: "put",
        ...config,
      }),
      invalidatesTags: (data, error) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER),
    }),
    getAccountProfilePic: builder.query({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/profile-pic`,
        ...config,
      }),
      providesTags: (data) => providesTags([data], StoreQueryTagEnum.USER),
    }),
    getRiders: builder.query({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/riders`,
        method: "get",
        ...config,
      }),
    }),
    getDrivers: builder.query({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/drivers`,
        method: "get",
        ...config,
      }),
    }),
    getAdmins: builder.query({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/admins`,
        method: "get",
        ...config,
      }),
    }),
    createAdminAccount: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/me`,
        method: "put",
        ...config,
      }),
      invalidatesTags: (data, error) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER),
    }),
    updateAdminAccount: builder.mutation({
      query: ({ path, ...config }) => ({
        url: `${ACCOUNT_API_URL}/v1/${path?.id}`,
        method: "put",
        ...config,
      }),
      invalidatesTags: (data, error) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER),
    }),
    sendAccountActivation: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/me`,
        method: "put",
        ...config,
      }),
      invalidatesTags: (data, error) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER),
    }),
    refreshAccountToken: builder.mutation({
      query: (config) => ({
        url: `${ACCOUNT_API_URL}/v1/token-refresh`,
        method: "post",
        ...config,
      }),
      invalidatesTags: (data, error) =>
        error ? [] : invalidatesTags(StoreQueryTagEnum.USER),
    }),
  }),
});

export default AccountApi;
