import axios from "axios";

export const AppHttp = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const CloudinaryHttp = axios.create({
  baseURL: process.env.REACT_APP_CLOUDINARY_API_URL,
});

export default AppHttp;
