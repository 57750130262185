export const DateFormatEnum = {
  FORMAT: "yyyy-MM-dd",
  SPACE_dd_MMM_yyyy: "dd MMMM yyyy",
  HYPHEN_yyyy_MM_dd: "yyyy-MM-dd",
  HYPHEN_yyyy_MM_dd_HH_mm_ss: "yyyy-MM-dd HH:mm:ss",
  HYPHEN_yyyy_MM_dd_HH_mm_ss_bbb: "yyyy-MM-dd HH:mm:ss bbb",
  HYPHEN_dd_MM_yyyy: "dd-MM-yyyy",
  HYPHEN_MM_dd_yyyy: "MM-ddd-yyyy",
};

export const DateLocaleEnum = {
  LOCALE: "en",
};

export const DateMonthToIndex = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DateWeekDayToIndex = [
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
];
