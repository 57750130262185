import { SubDomainEnum } from "constants/Global";

export function getAppSubdomain() {
  const splits = window.location.host.split(/[\\.:]/);
  if (splits?.[0] === "dev") {
    return splits[1];
  }
  return splits?.[0];
}

export function getAppSubdomainBooleans(subdomain = getAppSubdomain()) {
  const isFaanDashboard = [SubDomainEnum.FAAN_DASHBOARD].includes(subdomain);
  const isDefault =
    [SubDomainEnum.DEFAULT, "localhost", "127.0.0.1"].includes(subdomain) ||
    !isFaanDashboard;
  return { isDefault, isFaanDashboard };
}
